import * as React from 'react'
import Layout from '../components/layout'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const FacilityType = () => {
    return (
        <Layout pageTitle='Select Your Facility Type' id="works">
            <ul className='horizontalList'>
                <li style={{ animation: "fadeIn .75s ease-in-out forwards", opacity: 0 }}>
                    <button onClick={()=>{navigate('/Graphic/?cat=Industrial')}}>
                        <StaticImage
                            alt="industrial"
                            src="../images/facility-industrial.jpg"
                        />
                        <span>Industrial</span>
                    </button>
                </li>
                <li style={{ animation: "fadeIn .75s ease-in-out 1s forwards", opacity: 0 }}>
                    <button onClick={()=>{navigate('/Graphic/?cat=Retail')}}>
                        <StaticImage
                            alt="retail"
                            src="../images/facility-retail.jpg"
                        />
                        <span>Retail</span>
                    </button>
                </li>
                <li style={{ animation: "fadeIn .75s ease-in-out 2s forwards", opacity: 0 }}>
                    <button onClick={()=>{navigate('/Graphic/?cat=Gov')}}>
                        <StaticImage
                            alt="government"
                            src="../images/facility-government.jpg"
                        />
                       <span>Government</span>
                    </button>
                </li>
                <li style={{ animation: "fadeIn .75s ease-in-out 3s forwards", opacity: 0 }}>
                    <button onClick={()=>{navigate('/Graphic/?cat=Health')}}>
                        <StaticImage
                            alt="healthcare"
                            src="../images/facility-healthcare.jpg"
                        />
                        <span>Healthcare</span>
                    </button>
                </li>
            </ul>
        </Layout>
    )
}

export default FacilityType